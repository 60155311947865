import dayjs from 'dayjs'
import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

const actionCreator = actionCreatorFactory()

export type ChoroplethType =
    | 'est_water_level'
    | 'est_ground_temperature'
    | 'est_water_temperature'
    | 'est_day_water_temperature'
    | 'est_night_water_temperature'
    | 'est_diff_water_temperature'
    | 'est_mineralization_rate'
    | 'sensing_water_level'
    | 'sensing_ground_temperature'
    | 'sensing_water_temperature'
    | 'sensing_day_water_temperature'
    | 'sensing_night_water_temperature'
    | 'sensing_diff_water_temperature'
    | 'temperature'
    | 'day_temperature'
    | 'night_temperature'
    | 'diff_temperature'
    | 'acc_temperature_post_heading'
    | 'ripening_rate'

export type SuimonMode = 'view' | 'insert' | 'delete'

export type BottomFrameType =
    | 'none'
    | 'chart'
    | 'photolist'
    | 'suimontable'
    | 'fieldtable'
    | 'soilreport'

// UFieldGroup選択
export const selectUFieldGroup = actionCreator<{
    id?: string
}>('ACTION_TYPE_SELECT_UFIELD_GROUP')

// UField選択
export const selectUserField = actionCreator<{
    id?: string
}>('ACTION_TYPE_SELECT_USER_FIELD')

// 農地バンクデータ選択
export const selectFieldPolygon = actionCreator<{
    key?: string
    fieldKind?: string
    cityCode?: string
}>('ACTION_TYPE_SELECT_FIELD_POLYGON')

// キャプチャ画像選択
export const selectCaptureImagePath = actionCreator<{
    path?: string
}>('ACTION_TYPE_SELECT_CAPTURE_IMAGE_PATH')

// 農地バンク(2019年)の表示・非表示
export const setShowFieldPolygon2019 = actionCreator<{
    isShowFieldPolygon2019: boolean
}>('ACTION_TYPE_SHOW_FIELD_POLYGON_2019')

// 農地バンク(2021年)の表示・非表示
export const setShowFieldPolygon2021 = actionCreator<{
    isShowFieldPolygon2021: boolean
}>('ACTION_TYPE_SHOW_FIELD_POLYGON_2021')

// サムネイルの表示・非表示
export const setShowThumbnail = actionCreator<{
    isShowThumbnail: boolean
}>('ACTION_TYPE_SHOW_THUMBNAIL')

// 写真のある圃場の表示・非表示
export const setShowHasPhoto = actionCreator<{
    isShowHasPhoto: boolean
}>('ACTION_TYPE_SHOW_HAS_PHOTO')

// 水門モードの表示・非表示
export const setSuimonMode = actionCreator<{
    suimonMode: SuimonMode
}>('ACTION_TYPE_SUIMON_MODE')

// 水門の選択
export const selectSuimon = actionCreator<{
    id?: string
}>('ACTION_TYPE_SELECT_SUIMON')

// コロプレス表示の期間セット
export const setChoroplethTerm = actionCreator<{
    start: number
    end: number
}>('ACTION_TYPE_SET_CHOROPLETH_TERM')

// コロプレスの表示種別のセット
export const setChoroplethType = actionCreator<{
    type?: ChoroplethType
}>('ACTION_TYPE_SET_CHOROPLETH_TYPE')

// 下フレームに表示するコンテンツのセット
export const setBottomFrameType = actionCreator<{
    type?: BottomFrameType
}>('ACTION_TYPE_SET_BOTTOM_FRAME_TYPE')

// UI情報State
export interface UIState {
    selectedFieldPolygon: {
        readonly key?: string
        readonly fieldKind?: string // 農地バンク(2021年) のデータのみ存在
        readonly cityCode?: string // 農地バンク(2021年) のデータのみ存在
    }
    readonly selectedUFieldGroupId?: string
    readonly selectedUserFieldId?: string
    readonly selectedCaptureImagePath?: string
    readonly isShowFieldPolygon2019: boolean
    readonly isShowFieldPolygon2021: boolean
    readonly isShowThumbnail: boolean
    readonly isShowHasPhoto: boolean
    readonly suimonMode?: SuimonMode
    readonly selectedSuimonId?: string
    readonly choroplethTermStart: number
    readonly choroplethTermEnd: number
    readonly choroplethType?: ChoroplethType
    readonly bottomFrameType: BottomFrameType
}

const defaultState: UIState = {
    selectedFieldPolygon: {
        key: undefined,
        fieldKind: undefined,
        cityCode: undefined,
    },
    selectedUFieldGroupId: undefined,
    selectedUserFieldId: undefined,
    selectedCaptureImagePath: undefined,
    isShowFieldPolygon2019: false,
    isShowFieldPolygon2021: false,
    isShowThumbnail: false,
    isShowHasPhoto: false,
    suimonMode: undefined,
    selectedSuimonId: undefined,
    choroplethTermStart: dayjs().valueOf(),
    choroplethTermEnd: dayjs().add(2, 'hour').valueOf(),
    choroplethType: undefined,
    bottomFrameType: 'none',
}

// UI情報Reducer
export const uiStateReducer = reducerWithInitialState(defaultState)
    .case(selectUFieldGroup, (state, payload) => ({
        ...state,
        selectedUFieldGroupId: payload.id,
        selectedUserFieldId: undefined,
        selectedFieldPolygon: {
            key: undefined,
            fieldKind: undefined,
            cityCode: undefined,
        },
    }))
    .case(selectUserField, (state, payload) => {
        if (state.suimonMode) return state // 水門モードのときはuField選択なし
        return {
            ...state,
            selectedUserFieldId: payload.id,
            selectedFieldPolygon: {
                key: undefined,
                fieldKind: undefined,
                cityCode: undefined,
            },
            choroplethType: undefined,
        }
    })
    .case(selectFieldPolygon, (state, payload) => ({
        ...state,
        selectedUserFieldId: undefined,
        selectedFieldPolygon: {
            key: payload.key,
            fieldKind: payload.fieldKind,
            cityCode: payload.cityCode,
        },
    }))
    .case(selectCaptureImagePath, (state, payload) => ({
        ...state,
        selectedCaptureImagePath: payload.path,
    }))
    .case(setShowFieldPolygon2019, (state, payload) => ({
        ...state,
        isShowFieldPolygon2019: payload.isShowFieldPolygon2019,
    }))
    .case(setShowFieldPolygon2021, (state, payload) => ({
        ...state,
        isShowFieldPolygon2021: payload.isShowFieldPolygon2021,
    }))
    .case(setShowThumbnail, (state, payload) => ({
        ...state,
        isShowThumbnail: payload.isShowThumbnail,
    }))
    .case(setShowHasPhoto, (state, payload) => ({
        ...state,
        isShowHasPhoto: payload.isShowHasPhoto,
    }))
    .case(setSuimonMode, (state, payload) => ({
        ...state,
        selectedUserFieldId: undefined,
        suimonMode: payload.suimonMode,
        selectedSuimonId: undefined,
    }))
    .case(selectSuimon, (state, payload) => ({
        ...state,
        selectedSuimonId: payload.id,
    }))
    .case(setChoroplethTerm, (state, payload) => ({
        ...state,
        choroplethTermStart: payload.start,
        choroplethTermEnd: payload.end,
    }))
    .case(setChoroplethType, (state, payload) => ({
        ...state,
        selectedUserFieldId: undefined,
        choroplethType: payload.type,
    }))
    .case(setBottomFrameType, (state, payload) => ({
        ...state,
        bottomFrameType: payload.type,
    }))
