import { gql } from '@apollo/client'

// 農場リスト取得
export const GET_FARM_LIST = gql`
    query GetFarmList {
        nile_farm_organization(order_by: { materialized_path: asc }) {
            id
            materialized_path
            name
            nile_farm_farms(where: { valid_end: { _gt: "now()" } }) {
                id
            }
        }
    }
`

// ユーザ情報取得
export const GET_USER_INFO = gql`
    query GetUserInfo($username: String) {
        auth_user(where: { username: { _eq: $username } }) {
            username
            last_name
            first_name
            email
            is_active
            is_staff
            is_superuser
            last_login
            nile_farm_userorganizations {
                id
                nile_farm_organization {
                    id
                    name
                }
            }
        }
    }
`
