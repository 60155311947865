import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    split,
    useQuery,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import { GET_USER_INFO } from '../common/APIQuery'
import { initAxiosConfig } from '../common/NsAxiosHelper'
import { UserToken } from '../common/UserToken'
import { canLogin, DecodedJwt } from '../common/UserUtil'
import { FARMING_GRAPHQL_URI, GRAPHQL_URI, JWT_TOKEN_URL, LOGIN_URL } from '../const/UrlConst'
import { GetUserInfoQuery } from '../generated/graphql'
import store, { RootState } from '../store'
import { setAuthUserQueryAction, setUserTokenAction } from '../store/UserProfile'
import '../styles/marker.css'

// Axiosの初期化
initAxiosConfig()

/**
 * ユーザ情報を取得してからコンポーネントを表示
 */
const ComponentWithUserInfo: React.FC<{ props: AppProps }> = (param) => {
    const dispatch = useDispatch()
    const username = useSelector((state: RootState) => state.userProfile.userToken.username)

    // ユーザ情報取得処理
    const query = useQuery<GetUserInfoQuery>(GET_USER_INFO, { variables: { username } })

    useEffect(() => {
        const data = query.data
        if (!data) return
        if (data.auth_user && data.auth_user.length > 0) {
            const user = data.auth_user[0]
            const org = user.nile_farm_userorganizations?.[0]
            const user_organization_id = org?.id
            const organization_id = org?.nile_farm_organization?.id
            const userInfo = { ...user, user_organization_id, organization_id }
            dispatch(setAuthUserQueryAction({ userInfo }))
            Sentry.setContext('userInfo', userInfo)
        }
    }, [query.data])

    if (!query.data) {
        return <>Loading User Info...</>
    }

    return (
        <SnackbarProvider maxSnack={3}>
            <param.props.Component {...param.props.pageProps} />
        </SnackbarProvider>
    )
}

/**
 * ログインしている場合のみコンポーネントを表示
 */
const ComponentWithLogin: React.FC<{ props: AppProps }> = (param) => {
    const token = useSelector((state: RootState) => state.userProfile.userToken)
    const [error, setError] = useState('')

    useEffect(() => {
        // JWTトークンをAPIサーバから取得する
        axios
            .get(JWT_TOKEN_URL)
            .then((value) => {
                const decodedJwt = jwtDecode<DecodedJwt>(value.data.token)
                const userToken: UserToken = {
                    ...value.data,
                    user_organization_id: decodedJwt.user_organization_id,
                    organizationMaterializedPath:
                        decodedJwt['https://hasura.io/jwt/claims'][
                            'x-hasura-organization-materialized-path'
                        ],
                }
                // Sentry.setContext('userToken', userToken)
                store.dispatch(setUserTokenAction({ userToken }))
            })
            .catch((ex) => {
                // eslint-disable-next-line no-console
                console.error(ex)
                setError('Login Error: ' + ex.message)
            })
    }, [])

    if (!token) {
        return (
            <div>
                <button
                    onClick={() => {
                        location.reload()
                    }}
                >
                    Reload
                </button>
                <br />
                {error}
            </div>
        )
    }

    const getWebSocketSplitLink = (uri: string) => {
        const httpLinkApi = new HttpLink({
            uri: uri,
            headers: { authorization: `Bearer ${token.token}` },
        })
        const wsLinkApi = new WebSocketLink({
            uri: uri.replace(/http:\/\//, 'ws://').replace(/https:\/\//, 'wss://'),
            options: {
                reconnect: true,
                connectionParams: {
                    headers: { authorization: `Bearer ${token.token}` },
                },
            },
        })
        return split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                )
            },
            wsLinkApi,
            httpLinkApi
        )
    }

    const linkApi = getWebSocketSplitLink(GRAPHQL_URI)
    const linkFarming = getWebSocketSplitLink(FARMING_GRAPHQL_URI)

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.split(
            (operation) => operation.getContext().clientName === 'farming',
            linkFarming,
            linkApi
        ),
        connectToDevTools: !process.env.NEXT_PUBLIC_FARMING_GRAPHQL_BASE_URL,
    })

    if (!canLogin(token))
        return (
            <div>
                お使いのアカウントでは本サービスを利用できません。
                <a href={LOGIN_URL}>別のアカウントでログイン</a>
            </div>
        )

    return (
        <ApolloProvider client={client}>
            <GlobalStyle />
            <ComponentWithUserInfo props={param.props} />
        </ApolloProvider>
    )
}

/**
 * Appルート
 */
const App = (props: AppProps): JSX.Element => {
    return (
        <Provider store={store}>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no, viewport-fit=cover"
                />
                <title>営農支援</title>
                <link rel="manifest" href="/manifest.json" />
                <link href="/icons/icon-16x16.png" rel="icon" type="image/png" sizes="16x16" />
                <link href="/icons/icon-32x32.png" rel="icon" type="image/png" sizes="32x32" />
                <link rel="apple-touch-icon" href="/apple-icon.png"></link>
                <meta name="theme-color" content="#317EFB" />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                />
            </Head>
            <ComponentWithLogin props={props} />
        </Provider>
    )
}

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: Roboto;
    -webkit-app-region: drag;
    height: 100%;
    overflow: hidden;
    padding: env(safe-area-inset);
  }
  body {
    background-color: rgb(40, 44, 52);
    color: rgb(0, 0, 0);
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0
  }
  #root {
    height: 100%;
    width: 100%;
  }
`

export default App
