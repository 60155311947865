import { UserToken } from './UserToken'

export type DecodedJwtClaims = {
    'x-hasura-user-id': string
    'x-hasura-default-role': string
    'x-hasura-allowed-roles': string[]
    'x-hasura-organization-materialized-path': string
}

export type DecodedJwt = {
    iat: number
    exp: number
    username: string
    user_organization_id: string
    is_staff: boolean
    role: string
    'https://hasura.io/jwt/claims': DecodedJwtClaims
}

export const canLogin = (userToken: UserToken): boolean => {
    return userToken.allowedRoles.some(
        (role) => role === 'nileworks_staff' || role === 'sharing_staff' || role === 'sharing_user'
    )
}

export const canManageSuimon = (userToken: UserToken): boolean => {
    return userToken.allowedRoles.some(
        (role) => role === 'nileworks_staff' || role === 'sharing_staff'
    )
}

export const isNileworksStaff = (userToken: UserToken): boolean => {
    return userToken.allowedRoles.some((role) => role === 'nileworks_staff')
}
