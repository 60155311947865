import { actionCreatorFactory } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { UserToken } from '../common/UserToken'

const actionCreator = actionCreatorFactory()

export interface UserInfo {
    email: string
    first_name: string
    is_active: boolean
    is_staff: boolean
    is_superuser: boolean
    last_name: string
    username: string
    user_organization_id: string
    organization_id: string
}

// ユーザトークンAction
export const setUserTokenAction = actionCreator<{
    userToken?: UserToken
}>('ACTION_TYPE_USER_TOKEN')

// ユーザ情報Action
export const setAuthUserQueryAction = actionCreator<{
    userInfo?: UserInfo
}>('ACTION_TYPE_USER_QUERY')

// ユーザ情報State
export interface UserProfileState {
    readonly userToken?: UserToken
    readonly userInfo?: UserInfo
}

const defaultState: UserProfileState = {
    userToken: undefined,
    userInfo: undefined,
}

// ユーザ情報Reducer
export const userProfileReducer = reducerWithInitialState(defaultState)
    .case(setUserTokenAction, (state, payload) => ({
        ...state,
        userToken: payload.userToken,
    }))
    .case(setAuthUserQueryAction, (state, payload) => ({
        ...state,
        userInfo: payload.userInfo,
    }))
