import { applyMiddleware, combineReducers, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { UIState, uiStateReducer } from './UIState'
import { userProfileReducer, UserProfileState } from './UserProfile'

/**
 * 最上位のState
 * Stateを新規追加した場合は、以下にも追加する
 */
export interface RootState {
    userProfile: UserProfileState
    uiState: UIState
}

/**
 * 最上位のReducer
 * Reducerを新規追加した場合は、以下にも追加する
 */
const rootReducer = combineReducers<RootState | undefined>({
    userProfile: userProfileReducer,
    uiState: uiStateReducer,
})

// Storeの設定
const configureStore = (initialState?: RootState): Store<RootState | undefined> => {
    const middlewares: any[] = []
    const enhancer = composeWithDevTools(applyMiddleware(...middlewares))
    const store = createStore(rootReducer, initialState, enhancer)
    return store
}

const store = configureStore()

export default store
